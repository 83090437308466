<!-- Control MultiFilter -->

<template>  
  <div class="uiMultiFilter" style="display:flex">
    
    <v-menu offset-y>
      <template v-slot:activator="{ on }">              
                             
        <v-text-field
          ref="txt"
          :style="sty"
          v-bind="$input"
          v-model="inputText"
          :label="seleccion.label"
          :disabled="disabled"          
          @keypress.13="$emit('buscar', nameFilter())">          

          <template slot="append">
            <v-icon @click="limpia">{{ "mdi-close-circle-outline" }}</v-icon>
            <v-icon @click="$emit('buscar', nameFilter())">{{ "mdi-filter-outline" }}</v-icon>
          </template>
        
          <!-- append-icon="mdi-close-circle-outline" -->
          <template v-slot:prepend-inner>
            <v-icon v-on="on">{{ "mdi-menu-down" }}</v-icon>
          </template>
        </v-text-field>

      </template>

      <!-- Lista de opciones de Busqueda -->
      <v-list>
        <div style="text-align:center;width:130px"> Seleccione filtro </div>
        <v-divider></v-divider>
        <v-list-item v-for="(item, index) in items" :key="index">
          <v-list-item-title @click="newSeleccion(item)">{{ item.label }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>



<script>

  export default {
    props: {
      sty: { type: String, default: "" },
      items: { type: [Object, Array], required: true },
      ct: { type: [Object, Array], required: true },
      searchFn: { type:Function, default: null },
      disabled: { type: Boolean, default: false }
    },

    data() {
      return {
        seleccion: ""
      };
    },

    created() {
      if (!this.items) return;
      this.seleccion= this.items[0];      
    },


    methods: {
      // blanquea el value del control inicialmente seleccionado
      // marca como seleccionado el pulsado y pone el foco en el input
      newSeleccion(item) {       
        this.$set(this.ct[this.seleccion.ctrl], 2, "");
        this.seleccion = item;
        this.$refs.txt.focus();

        // emite evento al cambiar el elemento del menú
        this.$emit('onChangeMenu', item);
      },


      // blanquea ctrl
      limpia() {
        this.inputText="";
      },


      // devuelve el nombre del filtro a buscar
      // si no se introuce un texto a filtrar devuelve 'buscar'
      // si se introduce texto devuelve el nombre del control seleccionado      
      nameFilter() {
        //if (!this.ct[this.seleccion.ctrl][2]) return 'buscar';
        return this.seleccion.ctrl;
      }
  
    },


    computed: {
      inputText: {
        get: function() {
          return this.ct[this.seleccion.ctrl][2];
        },

        set: function(modifiedValue) {
          this.$set(this.ct[this.seleccion.ctrl], 2, modifiedValue);
          this.$emit("input", modifiedValue);
        }
      }
    },      
    
  };

 
</script>

